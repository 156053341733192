<template>
  <div id="components-form-demo-vuex">
    <a-row>
      <a-col :span="12">
        <div class="form_field">
          <label>{{ $t("FormFirstName") }}</label>
          <a-input
            v-model="form.first_name"
            :placeholder="$t('FormFirstNamePlace')"
          />
        </div>

        <div class="form_field">
          <label>{{ $t("FormLastName") }}</label>
          <a-input
            v-model="form.last_name"
            :placeholder="$t('FormLastNamePlace')"
          />
        </div>

        <div class="form_field">
          <label>{{ $t("FormMiddleName") }}</label>
          <a-input
            v-model="form.middle_name"
            :placeholder="$t('FormMiddleNamePlace')"
          />
        </div>

        <div class="form_field">
          <label>{{ $t("FormBirthDate") }}</label>
          <a-date-picker
            v-model="form.birth_date"
            :format="'YYYY-MM-DD'"
            @change="onChange"
          />
        </div>

        <div class="form_field">
          <label>{{ $t("FormGender") }}</label>
          <a-radio-group v-model="form.gender">
            <a-radio
              v-for="(gender, index) in genderList"
              :key="index"
              :value="gender.value"
            >
              {{ gender.title }}
            </a-radio>
          </a-radio-group>
        </div>
      </a-col>

      <a-col :span="12">
        <div class="form_field">
          <label>{{ $t("FormPhone") }}</label>
          <a-input
            v-model="form.phone"
            :placeholder="$t('FormPhone')"
            name="phone"
            type="tel"
            :max-length="20"
            :allow-clear="true"
          />
        </div>

        <div class="form_field">
          <label>{{ $t("FormProfession") }}</label>
          <a-input
            v-model="form.profession"
            :placeholder="$t('FormProfessionPlace')"
          />
        </div>

        <div class="form_field">
          <label>
            {{ $t("FormUsername") }} <span style="color: red">*</span>
          </label>
          <a-input
            v-model="form.username"
            :required="true"
            :placeholder="$t('FormUsernamePlace')"
          />
        </div>

        <div class="form_field">
          <label>
            {{ $t("FormRole") }} <span style="color: red">*</span>
          </label>
          <a-select
            v-model="form.role"
            default-value="Admin"
            style="width: 120px"
          >
            <a-select-option
              v-for="role in roleList"
              :key="role.title"
              :value="role.value"
            >
              {{ role.title }}
            </a-select-option>
          </a-select>
        </div>

        <div v-if="!form.id" class="form_field">
          <label>
            {{ $t("FormPassword") }} <span style="color: red">*</span>
          </label>
          <a-input
            v-model="form.password"
            :placeholder="$t('FormPasswordPlace')"
          />
        </div>

        <div v-if="!form.id" class="form_field">
          <a-button type="primary" @click="handleSubmit">
            <a-icon type="plus" />{{
              this.$route.params.id ? $t("Save") : $t("Add")
            }}
          </a-button>
        </div>

        <div v-else class="form_field">
          <a-button type="primary" @click="openCreateModal">
            Изменить пароль
          </a-button>
          <a-button type="primary" @click="handleSubmit">
            <a-icon type="plus" />{{
              this.$route.params.id ? $t("Save") : $t("Add")
            }}
          </a-button>
        </div>
      </a-col>
    </a-row>

    <a-modal
      :visible="modalVisible"
      title="Добавить"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <!--<a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          {{ $t("FormUsername") }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input v-model="FormUpdatePassword.username" :disabled="true" />
        </a-col>
      </a-row>-->

      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Введите старый пароль <span style="color: red">*</span>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="FormUpdatePassword.old_password"
            placeholder="Введите старый пароль"
          />
        </a-col>
      </a-row>

      <a-row align="middle" class="flex-baseline">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Введите новый пароль <span style="color: red">*</span>
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-input
            v-model="FormUpdatePassword.password"
            placeholder="Введите новый пароль"
          />
        </a-col>
      </a-row>

      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
        <a-button type="primary" @click="updatePassword"> Сохранить </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators"
import cloneDeep from "lodash/cloneDeep"
export default {
  data() {
    return {
      modalVisible: false,
      form: {
        id: null,
        username: "",
        password: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        phone: "",
        gender: "",
        birth_date: "",
        profession: "",
        role: ""
      },
      FormUpdatePassword: {
        username: "",
        password: "",
        old_password: ""
      },
      roleList: [
        {
          value: "admin",
          title: "admin"
        },
        {
          value: "api_client",
          title: "api_client"
        },
        {
          value: "online_reception",
          title: "online_reception"
        },
        {
          value: "online_appealer",
          title: "online_appealer"
        },
        {
          value: "user",
          title: "user"
        }
        // {
        //   value: "Moderator",
        //   title: "Moderator"
        // },
        // {
        //   value: "Moderator2",
        //   title: "Moderator-2"
        // },
        // {
        //   value: "Moderator3",
        //   title: "Moderator-3"
        // }
      ],
      genderList: [
        {
          value: 1,
          title: this.$t("FormMale")
        },
        {
          value: 2,
          title: this.$t("FormFemale")
        }
      ],
      name: "",
      password: "",
      username: ""
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    username: {
      required,
      minLength: minLength(5)
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },
  created() {
    this.form.id = (this.$route.params && this.$route.params.id) || null
    this.fetchData()
  },
  methods: {
    async handleSubmit() {
      let newData = cloneDeep(this.form)
      delete newData.photo
      delete newData.thumb
      delete newData.address
      newData = {
        ...newData
      }
      let f = new FormData()
      Object.keys(newData).forEach((key) => {
        f.append(key, newData[key])
        // key !== "birth_date" && f.append(key, newData[key])
      })
      let res = this.form.id
        ? await this.$api.patch("/admin/user/" + this.form.id + "/update/", f)
        : await this.$api.post("/admin/user/create/", f)
      // console.log(res)
      if (res && (res.status === 200 || res.status === 201)) {
        await this.$router.push({
          name: "users"
        })
      }
    },
    async updatePassword() {
      try {
        let userID = this.$route.params.id
        let f = new FormData()
        Object.keys(this.FormUpdatePassword).forEach((key) => {
          f.append(key, this.FormUpdatePassword[key])
        })
        let res = await this.$api.patch(
          `admin/user/update/${userID}/password/`,
          f
        )
        // console.log(res)
        if (res && res.status === 200) {
          this.modalVisible = false
          this.$message.success("Пароль успешно изменен!!!")
        }
      } catch (error) {
        return error
      }
    },
    fetchData() {
      if (this.$route.params && this.$route.params.id) {
        this.$store
          .dispatch("user/detail", this.$route.params.id)
          .then((res) => {
            this.form = res && res.data
          })
      }
    },
    onChange(e) {
      if (e !== null) {
        e = this.moment(e).format("YYYY-MM-DD")
        this.form.birth_date = e
      } else {
        this.form.birth_date = ""
      }
    },
    openCreateModal() {
      this.modalVisible = true
      this.FormUpdatePassword = {
        username: this.form && this.form.username,
        password: "",
        old_password: ""
      }
    }
  }
}
</script>
<style>
#components-form-demo-vuex .language-bash {
  max-width: 400px;
  border-radius: 6px;
  margin-top: 24px;
}
form {
  padding: 64px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
form > div {
  width: 30%;
  margin: 15px 0;
}
form > div > label {
  display: block;
}

.form_field {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form_field > .deputy_image {
  width: 70px;
  height: 70px;
  border: 1px solid grey;
  border-radius: 40px;
  overflow: hidden;
  display: inline;
  margin-right: 30px;
}
.form_field > .deputy_image > img {
  width: 100%;
  height: 100%;
}
.form_field > label {
  margin-bottom: 7px;
  display: block;
  width: 100%;
}
.form_field > div {
  width: 100%;
}
.form_field > button {
  margin-left: auto;
}
.form_field_btn {
  margin: 10px 0;
  height: 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.form_field > .ant-select {
  width: 100% !important;
}
.form_field > .ant-calendar-picker {
  width: 100% !important;
}
</style>
